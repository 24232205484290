<template>
  <section ref="section" class="-visibility-auto dual-tile">
    <Observer name="dual-enter" @enter="enter">
      <div class="container">
        <router-link class="tile" v-for="tileItem in items" :key="tileItem.title" :to="localizedUrl + tileItem.link">
          <div class="image-container" :class="[ item && item.animated ? '-animated': '']">
            <LazyPicture :item="tileItem" preserve-ratio="true" />
          </div>
          <h2 :class="['title', tileItem.textColor === 'white' ? '-white' : '']" v-if="tileItem.title" v-text="tileItem.title"></h2>
        </router-link>
      </div>
    </Observer>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from '../utils/LazyPicture.vue';

export default {
  mixins: [ReadyHook],
  name: 'HRDualTile',
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  components: {
    LazyPicture
  },
  data() {
    return {
      appeared: false
    }
  },
  computed: {
    localizedUrl() {
      const { url } = currentStoreView()
      return url || ''
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  margin: 8rem 0;

  @include bp(max md) {
    margin: 4rem 0;
  }

  .container {
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 0 6rem;
    max-width: fit-content;

    @include bp(max md) {
      flex-direction: column;
      margin: 0;
    }

    .tile {
      display: flex;
      flex-direction: column;
      flex: 1;

      .image-container {
        overflow: hidden;
        aspect-ratio: 4/5;

        .lazy-picture {
          height: 100%;

          /deep/ img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }

        &:hover.-animated {
          .lazy-picture /deep/ img{
            transform: scale(1.1);
          }
        }
      }

      .title {
        font-weight: $weight-regular;
        text-align: center;
        text-decoration: underline;
        margin-bottom: 0;
        margin-top: 2rem;

        &.-white {
          color: var(--zenith-white);
        }
      }
    }
  }
}
</style>
